*{
  box-sizing: border-box;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-Regular.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-Medium.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-Light.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-Regular.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mplus 1p';
  src: url('./assets/fonts/Mplus1p-Thin.woff2') format('woff2'),
      url('./assets/fonts/Mplus1p-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Thin.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}



html, body{
width: 100%;
height: 100%;
font-family: 'Mplus 1p' !important;
}

#root{
  height: 100vh;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C1C1C1;
}
/* .MuiSvgIcon-root {
  color: #FFF;
} */