/* .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid red;
} */
.MuiDataGrid-columnHeaders{
    background-color: rgb(8 102 212) !important;
}
.MuiPickersToolbar-toolbar {
    display: none !important;
}
.Mui-checked {
    color: #fff !important;
}
.css-pahyoi {
    top: 0px !important;
}
.customDateBtn{
    display: flex;
    justify-content: space-around;
    padding: 2%;
}
.MuiPickersDay-daySelected {
    background: #12BFE0 !important
}
.customDateOption:hover{
    background: #12BFE0 !important;
    color: #fff;
}